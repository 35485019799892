.list-inline {
  list-style: none;
}

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}



.App-header {
  /* background-color: rgb(1, 121, 31); */
  background-color: #01791f;
  color: white;
}

#bag > span{  
  /* shopping cart badge */
  /* position: relative; */
  top: -8px;
  /* left: 7px; */ 
}


.dottedUnderline { border-bottom: 1px dotted; }

