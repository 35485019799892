/* modal-dialog{max-width: 800px; width: 100%;} */
/* .modal-xl{
   min-width: 1800;
} */


  @media (min-width: 768px) {
    .modal-xl {
      width: 90%;
     max-width:1500px;
    }
    .modal-content{

      background-color: #e4e4e4;
    }
  }
  .border-2-radius-bottom {
    border-width: 0.5rem !important;
    /* border-radius: 10px; */
    
  }


.itemimage_wrapper_item_detail .tinylogo{
  position: absolute;
  right: 0.0rem;
  top: -2.0rem;
  font-size: 40px;
   color: white;
}



  