
.card-title {
  margin-bottom: 0.1rem;
}

#menuitem{
  border-width: 1px 1px 1px 1px;
  border-radius: 3px;
  border-color: rgb(204, 203, 203)
}

.logo {
  position: absolute;
  /* left: -2px;
  top: -10px; */
   width: 25px;
  border-radius: 50px  ;
}

.itemtitle{
  font-size: 1.15rem;
  font-weight: 280;
}








/* .itemdescription{
  height: 4em;
  font-size: 85%;
  overflow: hidden; 
  text-overflow: ellipsis;
}  */



.itemdescription{
  --lh: 1.4rem;
  /* max-width: 22rem; */
  /* margin: 2rem auto; */
  line-height: var(--lh);
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.itemdescription::before {
  position: absolute;
  content: "...";
  /* tempting... but shows when lines == content */
  /* top: calc(var(--lh) * (var(--max-lines) - 1)); */
  
  /*
  inset-block-end: 0;
  inset-inline-end: 0;
  */
  bottom: 0;
  right: 0;
}
.itemdescription::after {
  content: "";
  position: absolute;
  /*
  inset-inline-end: 0;
  */
  right: 0;
  /* missing bottom on purpose*/
  width: 1rem;
  height: 1rem;
  background: white;
}




























.itemimage_wrapper .tinylogo{
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 40px;
   color: white;
}

.itemimage, .itemdescription, .itemtitle, .itemprice  {
   cursor: pointer;
}

.addQuickly_wrapper{
  position: absolute;
  bottom: 0em;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */

   width:100%; 
   padding: 0.5em;
   
  }
  
  /* .addQuickly_wrapper > button{
    
    
} */



.grayout {
  opacity: 0.4; /* Real browsers */
  filter: alpha(opacity = 60); /* MSIE */
}


.footnote{
  font-weight: 800;
  font-size: 0.80rem;
}

.footnote > .timeleft{
  font-weight: normal;
}

.cardpricewrapper > p{
  font-size: 1.3rem;
  font-weight: 500;
}

