/*   https://bootsnipp.com/snippets/featured/funky-radio-buttons */

.inputstyle div {
    /* clear: both;
    overflow: hidden;
    display: inline-flex; */
  }
  
  .inputstyle label {
    width: 100%;
    border-radius: 3px;
    border: 1px solid rgb(231, 231, 231);
    font-weight: normal;
  }

  .inputstyle input[type="radio"]~ label,
  .inputstyle input[type="checkbox"]~ label {
    padding-right: 1em;
  }

  .inputstyle input[type="radio"]:empty,
  .inputstyle input[type="checkbox"]:empty {
    display: none;
  }
  
  .inputstyle input[type="radio"]:empty ~ label,
  .inputstyle input[type="checkbox"]:empty ~ label {
    position: relative;
    line-height: 2.5em;
    text-indent: 3.25em;
    /* margin-top: 2em; */
    /* padding-right: 1em; */
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .inputstyle input[type="radio"]:empty ~ label:before,
  .inputstyle input[type="checkbox"]:empty ~ label:before {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    width: 2.5em;
    background: #F1F1F1;
    border-radius: 3px 0 0 3px;
  }
  
  .inputstyle input[type="radio"]:hover:not(:checked) ~ label,
  .inputstyle input[type="checkbox"]:hover:not(:checked) ~ label {
    /* color: #888;  disabled temporaryly*/  
    
  }


@media (hover: hover) {
  /* disable hover effect on touch screens, only on desktops */
  
  .inputstyle input[type="radio"]:hover:not(:checked) ~ label:before,
  .inputstyle input[type="checkbox"]:hover:not(:checked) ~ label:before {
    content: '\2714';
    text-indent: .9em;
    color: #C2C2C2;
    
  }
  
  .inputstyle input[type="radio"]:hover:checked ~ label:before,
  .inputstyle input[type="checkbox"]:hover:checked ~ label:before {
    content: '\2714';
    text-indent: .9em;
    /* font-size: 8rem; */
    
  }

}


  .inputstyle input[type="radio"]:checked ~ label,
  .inputstyle input[type="checkbox"]:checked ~ label {
    font-weight: 600;
    color:#666666;
  }
  
  .inputstyle input[type="radio"]:checked ~ label:before,
  .inputstyle input[type="checkbox"]:checked ~ label:before {
    content: '\2714';
    text-indent: .9em;
    color: #333;
    background-color: #ccc;
  }
  
  .inputstyle input[type="radio"]:focus ~ label:before,
  .inputstyle input[type="checkbox"]:focus ~ label:before {
    box-shadow: 0 0 0 3px #999;
  }
  
  .inputstyle-default input[type="radio"]:checked ~ label:before,
  .inputstyle-default input[type="checkbox"]:checked ~ label:before {
    color: #333;
    background-color: #ccc;
  }
  
  .inputstyle-primary input[type="radio"]:checked ~ label:before,
  .inputstyle-primary input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #337ab7;
  }
  
  .inputstyle-success input[type="radio"]:checked ~ label:before,
  .inputstyle-success input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #5cb85c;
  }
  
  .inputstyle-danger input[type="radio"]:checked ~ label:before,
  .inputstyle-danger input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #d9534f;
  }
  
  .inputstyle-warning input[type="radio"]:checked ~ label:before,
  .inputstyle-warning input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #f0ad4e;
  }
  
  .inputstyle-info input[type="radio"]:checked ~ label:before,
  .inputstyle-info input[type="checkbox"]:checked ~ label:before {
    color: #fff;
    background-color: #5bc0de;
  }