body {
  margin: 0;
  padding: 0;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 1.4em;

}
root {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", Arial, sans-serif; 
}




body, html{
  height: 100%;
}