.storename {
  font-size: 14px;
  @include media-breakpoint-up(sm) { 
    font-size: 24px;
  }
  @include media-breakpoint-up(md) { 
    font-size: 34px;
  }
}

.restwrapper .tinybadge{
  position: relative;
  left: 82%;
  top: -12.5rem;
  font-size: 11px;
  color: white;
}

.card:hover{
  border-color: green;
  border-width: 2;
}

.grayout {
  opacity: 0.4; /* Real browsers */
  filter: alpha(opacity = 60); /* MSIE */
}

.slick-prev:before {
  color: #01791f;
}

.slick-next:before {
  color: #01791f;
}

.slick-next {
  right: -10px;
  top: 58px;
  /* width: 40px;
  height: 40px; */
}

.slick-list {
  margin-left: 15px;
  margin-right: 15px;
}

.slick-prev {
  left: -10px;
  top: 58px;
  /* width: 40px;
  height: 40px; */
}


.icon-container {
  display:inline-block;
  position:relative;

 
}



.logo-icon {
  /* top:2px; */
  bottom:-12px;
  position:absolute;
  right:2px;
  width: 25%;
  max-width: 200px;
}