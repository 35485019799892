

.page1 {
  height: 100vh;
  background: url('https://togonowassets.imgix.net/homepage_default.jpg?w=1200&auto=compress');
  /* background: url('https://togonowassets.imgix.net/homepage_default.jpg?w=1200'); */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}
.page1 header {
  top: 10px;
  /* position: absolute; */
  position: relative;
}
.page1 h1, .page1 h3 {
  color: yellow;
  
}

.centerdiv {
  display: block;
  justify-content: center;
  align-items: center;
  height: 200px;
  position: absolute;
  top: 40%;
}



.box > .icon { text-align: center; position: relative; }
.box > .icon > .image { position: relative; z-index: 2; margin: auto; width: 88px; height: 88px; border: 5px solid #f5f2f2; line-height: 88px; border-radius: 50%; background: white; vertical-align: middle; }
.box > .icon:hover > .image { background: rgb(169, 223, 175); }
.box > .icon > .image > i { font-size: 36px !important; color: red !important; }
.box > .icon:hover > .image > i { color: yellow !important; }
.box > .icon > .info { margin-top: -24px; background: rgba(0, 0, 0, 0.04); border: 1px solid #e0e0e0; padding: 28px 0 10px 0; }
.box > .icon:hover > .info { background: rgba(0, 0, 0, 0.04); border-color: #e0e0e0; color: green; }
.box > .icon > .info > h3.title { font-family: "Roboto",sans-serif !important; font-size: 16px; color: #222; font-weight: 500; }
.box > .icon > .info > p { font-family: "Roboto",sans-serif !important; font-size: 13px; color: #666; line-height: 1.5em; margin: 10px 15px;}
.box > .icon:hover > .info > h3.title, .box > .icon:hover > .info > p, .box > .icon:hover > .info > .more > a { color: #222; }
.box > .icon > .info > .more a { font-family: "Roboto",sans-serif !important; font-size: 12px; color: #222; line-height: 12px; text-transform: uppercase; text-decoration: none; }
.box > .icon:hover > .info > .more > a { color: #fff; padding: 6px 8px; background-color: #63B76C; }
.box .space { height: 30px; }






h3{
  font-family: 'Josefin Sans', sans-serif;
}

.box{
 padding:0px 0px 30px 0px;
}

.box-part{
  border-radius:10px;
  padding:60px 10px;
  margin:30px 0px;
}

.box-part:hover{
  background:rgb(180, 178, 178);
}

.box-part:hover .fa , 
.box-part:hover .title , 
.box-part:hover .text ,
.box-part:hover a{
 color:#FFF;
 -webkit-transition: all 1s ease-out;
 -moz-transition: all 1s ease-out;
 -o-transition: all 1s ease-out;
 transition: all 1s ease-out;
}

.text{
 margin:20px 0px;
}



.page2{
  background: url('https://togonowassets.imgix.net/whytogonowbackground.jpg');
  background-size: cover;
  background-position: center;
}


.pageWrapper{
  background:  radial-gradient(circle, rgba(0,0,0,0) 4%, rgba(255,255,255,0.5) 30%);;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

