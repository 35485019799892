

.spinner div { 
  width: 100px; height: 100px; position: absolute; 
  top:0; bottom: 0;
   left: 0; right: 0;
    margin: auto; 
}


.spinner {
  position: fixed;
  top: 0; right: 0;
  bottom: 0; left: 0;
  /* background: #aaa; */
}
.spinnerloading {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
}
.spinnerloading, .spinnerloading:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}
